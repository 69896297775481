import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
import Faqs from '../components/faqs';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import ReactBanner from '../images/react-banner2.png';

export default function Shopify() {
  return (
    <div className="page-super-container services-page">
      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="Shopify" bannerimg={ReactBanner} />

      <div>
        {
          Data.shopifypage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serShopifyCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">ENHANCING E-COMMERCE SUCCESS WITH SHOPIFY</div>
              <div className="about-sub-heading">Revolutionize Your Business with Customizable and Secure E-Commerce Solutions</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serShopifyPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="SHOPIFY DEVELOPMENT SERVICES"
        sersubHeading="Unleash the Full Potential of Your Online Store with Our Expert Shopify Development Services"
        data={Data.shopifyexpert}
      />
      
      <div>
        {
          Data.serWhyChooseShopify.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR SHOPIFY DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding Shopify Development Services"
        data={Data.shopifyFaqs}
      />

      <Footer />

    </div>
  )
}
