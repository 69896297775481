import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
import Faqs from '../components/faqs';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";

import ReactBanner from '../images/react-banner2.png';

export default function ReactJS() {
  return (
    <div className="page-super-container services-page">
      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="ReactJS" bannerimg={ReactBanner} />

      <div>
        {
          Data.reactpage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serReactCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">UPSCALE YOUR BUSINESS WITH GREAT REACTJS APP DEVELOPMENT</div>
              <div className="about-sub-heading">Why Choosing ReactJS Development Company Is A Right Move?</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serReactPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="REACT JS DEVELOPMENT SERVICES"
        sersubHeading="Elevate Your Digital Impact with Exceptional Apps Through Our Top-notch ReactJS Development Services"
        data={Data.reactexpert}
      />
      
      <div>
        {
          Data.serWhyChooseReact.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR REACTJS DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding ReactJS Development Services"
        data={Data.reactFaqs}
      />

      <Footer />

    </div>
  )
}
