import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// import Navigationbar from '../components/navbar';
import ServiceDetails from '../components/services-top-details';
import Banner from '../components/page-banner';
import Footer from '../components/footer';
import { Data } from '../components/Data';
import Experts from '../components/experts';
import ContactCard from '../components/contact-card';
import Serwhychoose from '../components/ser-WhyChoose';
// import Faqs from '../components/faqs';
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";
import ReactBanner from '../images/react-banner2.png';

function UiuxDesign() {
  return (
    <div className="page-super-container services-page">
      {/* <Navigationbar /> */}
      <NavbarWithMegaMenu />
      <Banner page="UI / UX Design" bannerimg={ReactBanner} />

      <div>
        {
          Data.uiuxpage.map(
            (value) => {
              return <ServiceDetails
                Heading={value.Heading}
                para={value.para}
                images={value.images}
                data={Data.serUiuxCard}
              />
            }
          )
        }
      </div>

      {/* Card */}
      <div className="contact-super-container">
        <Container className="contact-main-container">
          <Row>
            <Col>
              <div className="about-main-heading">TRANSFORMATIVE UX/UI DESIGN SERVICES</div>
              <div className="about-sub-heading">Crafting Exceptional Digital Experiences for Enhanced User Interaction</div>
            </Col>
          </Row>
          <Row xs={1} sm={2} md={2} lg={3} className="contact-card-container">
            {
              Data.serUiuxPaheCard.map(
                (value) => {
                  return <>
                    <ContactCard contactIcon={value.aboutcardtIcon} contactHeading={value.aboutcardheading} contactPara={value.aboutcardpara} />
                  </>
                }
              )
            }
          </Row>
        </Container>
      </div>

      <Experts
        serHeading="UI / UX DESIGN METHODOLOGY"
        sersubHeading="Strategic Frameworks for Designing Meaningful Interactions"
        data={Data.uiuxexpert}
      />
      
      <div>
        {
          Data.serWhyChooseUiux.map(
            (value) => {
              return <Serwhychoose
                Heading={value.Heading}
                subHeading={value.subHeading}
                para1={value.para1}
                para2={value.para2}
                para3={value.para3}
                images={value.images}
              />
            }
          )
        }
      </div>

      {/* <Faqs
        faqHeading="FREQUENTLY ASKED QUESTIONS FOR REACTJS DEVELOPMENT SERVICES"
        faqsubHeading="Discover Answers to Common Queries Regarding ReactJS Development Services"
        data={Data.reactFaqs}
      /> */}

      <Footer />

    </div>
  )
}

export default UiuxDesign;