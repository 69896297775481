/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Navigationbar from '../components/navbar';
import Banner from "../components/page-banner";
import { Container, Row } from "react-bootstrap";
import Title from "../components/title";
import { Data } from "../components/Data";
import Footer from "../components/footer";
import ContactCard from "../components/contact-card";
import contactbanner from "../images/contacts-banner.jpg";
import ContactForm from "../components/form";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";

export default function ContactUs() {
    return (
        <div className="page-super-container">
            {/* <Navigationbar /> */}
            <NavbarWithMegaMenu />
            <Banner page="Contacts" bannerimg={contactbanner} />
            <div className="contact-super-container">
                <Title
                    titleName={"OUR CONTACTS"}
                    subTitle={"We’re Here to Help You"}
                />
                <Container className="contact-main-container">
                    <Row
                        xs={1}
                        sm={2}
                        md={2}
                        lg={3}
                        className="contact-card-container"
                    >
                        {Data.contacts.map((value) => {
                            return (
                                <>
                                    <ContactCard
                                        contactIcon={value.contactIcon}
                                        contactHeading={value.contactHeading}
                                        contactPara={value.contactpara}
                                        contactPara2={value.contactpara2}
                                        contactLink={value.contactlink}
                                    />
                                </>
                            );
                        })}
                    </Row>
                </Container>
            </div>

            <ContactForm />

            <div className="contact-google-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14878.437414928769!2d72.846111!3d21.207672!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04efc094c0c51%3A0x43f3eb5f9c56cf0!2sMontech%20Design!5e0!3m2!1sen!2sin!4v1693660310947!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    className="iframe-border"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

            <Footer />
        </div>
    );
}
