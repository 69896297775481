import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card, TabContainer } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

// import Navigationbar from "../components/navbar";
import Banner from "../components/page-banner";
import ReactBanner from "../images/react-banner2.png";
import Footer from "../components/footer";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";

function Portfolio() {
    const [activeTab, setActiveTab] = useState("All");

    const [portfolioData, setPortfolioData] = useState([]);

    const fetchPortfolioData = async () => {
        try {
            const response = await axios.get("/Data.json");
            setPortfolioData(response.data.PortfolioData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchPortfolioData();
    }, []);

    const filterPortfolioByCategory = (category) => {
        if (category === "All") {
            return portfolioData;
        } else {
            return portfolioData.filter(
                (portfolio) => portfolio.category === category
            );
        }
    };

    const handleTabClick = (category) => {
        setActiveTab(category);
    };
    return (
        <div className="page-super-container portfolio-page">
            {/* <Navigationbar /> */}
            <NavbarWithMegaMenu />
            <Banner page="Portfolio" bannerimg={ReactBanner} />

            <Container className="exp-main-container">
                <Row>
                    <Col>
                        <div className="portfolio-main-heading">
                            IT EXPERIENCE
                        </div>
                        <div className="portfolio-sub-heading">
                            Our Case Studies
                        </div>
                        <div className="portfolio-para">
                            Many of the world's top brands have chosen Integrio
                            to help them design and deliver innovative products.
                            Here are just a few examples of our delighted
                            customers.
                        </div>
                    </Col>
                </Row>

                <TabContainer
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                >
                    <Row
                        xs={1}
                        sm="auto"
                        className="justify-content-sm-center port-tab-main-container"
                    >
                        <Col>
                            <Nav.Link onClick={() => handleTabClick("All")}>
                                <div
                                    className={`service-btn-main ${
                                        activeTab === "All" ? "active" : ""
                                    }`}
                                >
                                    <div className="service-arrow-icon">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#0089cf"
                                                stroke="none"
                                            >
                                                <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="service-item-link">All</div>
                                </div>
                            </Nav.Link>
                        </Col>

                        <Col>
                            <Nav.Link
                                onClick={() => handleTabClick("Development")}
                            >
                                <div
                                    className={`service-btn-main ${
                                        activeTab === "Development"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <div className="service-arrow-icon">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#0089cf"
                                                stroke="none"
                                            >
                                                <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="service-item-link">
                                        Development
                                    </div>
                                </div>
                            </Nav.Link>
                        </Col>

                        <Col>
                            <Nav.Link
                                onClick={() => handleTabClick("UI/UX Design")}
                            >
                                <div
                                    className={`service-btn-main ${
                                        activeTab === "UI/UX Design"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <div className="service-arrow-icon">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#0089cf"
                                                stroke="none"
                                            >
                                                <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="service-item-link">
                                        UI/UX Design
                                    </div>
                                </div>
                            </Nav.Link>
                        </Col>

                        <Col>
                            <Nav.Link onClick={() => handleTabClick("Logos")}>
                                <div
                                    className={`service-btn-main ${
                                        activeTab === "Logos" ? "active" : ""
                                    }`}
                                >
                                    <div className="service-arrow-icon">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#0089cf"
                                                stroke="none"
                                            >
                                                <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="service-item-link">
                                        Logos
                                    </div>
                                </div>
                            </Nav.Link>
                        </Col>

                        <Col>
                            <Nav.Link
                                onClick={() =>
                                    handleTabClick("Graphics Design")
                                }
                            >
                                <div
                                    className={`service-btn-main ${
                                        activeTab === "" ? "active" : ""
                                    }`}
                                >
                                    <div className="service-arrow-icon">
                                        <svg
                                            version="1.0"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#0089cf"
                                                stroke="none"
                                            >
                                                <path d="M3535 3821 c-104 -47 -153 -175 -106 -276 13 -26 143 -163 380 -400 199 -198 361 -363 361 -367 0 -5 -857 -8 -1903 -8 l-1904 0 -44 -23 c-68 -36 -103 -93 -107 -175 -4 -81 21 -138 81 -186 l39 -31 1919 -3 c1055 -1 1919 -6 1919 -10 0 -4 -164 -171 -365 -372 -394 -394 -403 -405 -393 -494 7 -60 50 -130 100 -163 56 -37 148 -44 204 -15 50 26 1145 1124 1170 1172 24 49 23 134 -4 185 -30 59 -1126 1150 -1174 1170 -50 20 -124 19 -173 -4z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="service-item-link">
                                        Graphics Design
                                    </div>
                                </div>
                            </Nav.Link>
                        </Col>
                    </Row>

                    <Container className="portfolio-tab-container">
                        <Row
                            xs={1}
                            sm={2}
                            md={2}
                            lg={3}
                            className="contact-card-container"
                        >
                            {filterPortfolioByCategory(activeTab).map(
                                (portfolio) => (
                                    <Col
                                        className="container-fluid mt-4"
                                        key={portfolio.id}
                                    >
                                        <Nav.Link
                                            as={Link}
                                            to={`/portfolio/${portfolio.id}?category=${activeTab}`}
                                            className="contant-card-link"
                                        >
                                            <Card className="portfolio-card under_image">
                                                <Card.Body className="portfolio-main-content">
                                                    <img
                                                        className="portfolio-img"
                                                        src={portfolio.image}
                                                        alt="poerfolio"
                                                    />
                                                    <div className="portfolio-img-overlay"></div>
                                                    <div className="">
                                                        <div className="portfolio-content-card">
                                                            <div className="portfolio-card-category">
                                                                {
                                                                    portfolio.category
                                                                }
                                                            </div>
                                                            <div className="portfolio-card-heading">
                                                                {
                                                                    portfolio.title
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Nav.Link>
                                    </Col>
                                )
                            )}
                        </Row>
                    </Container>
                </TabContainer>
            </Container>

            <Footer />
        </div>
    );
}

export default Portfolio;
