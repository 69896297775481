import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Navigationbar from '../components/navbar';
import About from "../components/about";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Banner from "../components/page-banner";
import Footer from "../components/footer";
import { Data } from "../components/Data";
import ContactCard from "../components/contact-card";
import aboutbanner from "../images/about-banner.jpg";
import aboutPagesecondImg from "../images/about-us-page-second.jpg";
import Teastimonials from "../components/testimonial";
import Title from "../components/title";
import ServiceCard from "../components/service-card";
import Logoslider from "../components/logo-slider";
import { NavbarWithMegaMenu } from "../NavbarWithMegaMenu";

export default function AboutUs() {
    return (
        <div className="page-super-container">
            {/* <Navigationbar /> */}
            <NavbarWithMegaMenu />
            <Banner page="About us" bannerimg={aboutbanner} />

            {/* about-page-first-section */}
            <div className="aboutpage-first-container">
                {Data.aboutpage.map((value) => {
                    return (
                        <About
                            heading={value.heading}
                            subHeading={value.subHeading}
                            mainpara={value.mainpara}
                            para={value.para}
                            images={value.images}
                            btnText={value.btnText}
                            btnLink={value.btnLink}
                        />
                    );
                })}
            </div>

            {/* Card */}
            <div className="contact-super-container">
                <Container className="contact-main-container">
                    <Row
                        xs={1}
                        sm={2}
                        md={2}
                        lg={3}
                        className="contact-card-container"
                    >
                        {Data.aboutPageCard.map((value) => {
                            return (
                                <>
                                    <ContactCard
                                        contactIcon={value.aboutcardtIcon}
                                        contactHeading={value.aboutcardheading}
                                        contactPara={value.aboutcardpara}
                                    />
                                </>
                            );
                        })}
                    </Row>
                </Container>
            </div>

            {/* Accordion */}
            <Container className="about-page-accordion-main-container">
                <Row xs={1} md={1} lg={2} className="row-container">
                    <Col className="about-page-content-col">
                        <div className="about-page-content-heading">
                            <div className="about-page-main-heading">
                                INNOVATIVE EXCELLENCE
                            </div>
                            <div className="about-page-sub-heading">
                                Quality That Inspires,
                            </div>
                            <div
                                className="about-page-sub-heading"
                                style={{ marginTop: "0px" }}
                            >
                                {" "}
                                Solutions That Transform
                            </div>
                            <div className="about-page-mainpara">
                                Innovative Excellence Redefining{" "}
                            </div>
                            <div
                                className="about-page-mainpara"
                                style={{ marginTop: "0px" }}
                            >
                                Digital Landscapes Every Day.
                            </div>
                            <Accordion
                                defaultActiveKey="0"
                                className="about-page-accordion-container"
                            >
                                {Data.aboutPageAccordion.map((value) => {
                                    return (
                                        <>
                                            <Accordion.Item
                                                eventKey={value.acckey}
                                                className="about-page-accordion-item"
                                            >
                                                <Accordion.Header className="about-page-accordion-line">
                                                    {value.accheading}
                                                </Accordion.Header>
                                                <Accordion.Body className="about-page-accordion-para">
                                                    {value.accpara}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </Col>
                    <Col className="about-page-img-col">
                        <img
                            className="about-page-images"
                            src={aboutPagesecondImg}
                            alt="about"
                        />
                    </Col>
                </Row>
            </Container>

            {/* Core Value */}
            <div className="about-page-core-card-super-container">
                <Title titleName={"OUR"} subTitle={"Core Values"} />
                <Container className="about-page-core-card-main-container">
                    <Row
                        xs={1}
                        sm={2}
                        md={2}
                        lg={4}
                        className="about-core-card-container"
                    >
                        {Data.aboutCoreValue.map((value) => {
                            return (
                                <>
                                    <ServiceCard
                                        cardIcon={value.coreIcon}
                                        cardHeading={value.coreheading}
                                        cardPara={value.corepara}
                                    />
                                </>
                            );
                        })}
                    </Row>
                </Container>
            </div>

            <Teastimonials />

            <Logoslider />

            <Footer />
        </div>
    );
}
